<template>
  <base-section id="reliable">
    <base-section-heading
      :title="heading"
    />

    <v-container>
      <v-row>
        <v-col
          v-for="card in cards"
          :key="card.title"
          cols="12"
          md="4"
        >
          <base-info-card
            v-bind="card"
            align="center"
          />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>
<script>
  export default {
    name: 'SectionReliable',
    created () {
      this.cards[0].title = this.$t('reliable.card1')
      this.cards[1].title = this.$t('reliable.card2')
      this.cards[2].title = this.$t('reliable.card3')

      this.$EventBus.$on('localeChanged', () => {
        this.cards[0].title = this.$t('reliable.card1')
        this.cards[1].title = this.$t('reliable.card2')
        this.cards[2].title = this.$t('reliable.card3')
      })
    },
    data () {
      return {
        cards: [
          {
            title: 'abcv',
            subtitle: '',
            text: '',
            callout: '',
            icon: 'mdi-certificate',
          },
          {
            title: 'bd',
            subtitle: '',
            text: '',
            callout: '',
            icon: 'mdi-currency-usd',
          },
          {
            title: 'cd',
            subtitle: '',
            text: '',
            callout: '',
            icon: 'mdi-lightbulb-on-outline',
          },
        ],
      }
    },
    computed: {
      heading () {
        return this.$t('reliable.heading')
      },
    },
  }
</script>
